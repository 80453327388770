<template>
  <v-card class="mx-auto text-center" width="100%" max-width="500" color="transparent" flat>
    <h1 class="primary--text">|MICROWD.</h1>

    <h2 class="my-3">El sistema se encuentra en mantenimiento</h2>

    <p>Para cualquier duda, ponte en contacto con soporte</p>
  </v-card>
</template>

<script>
export default {
  name: "Maintenance"
};
</script>

<style scoped>
h2 {
  line-height: 30px;
}
</style>
